@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;1,300&display=swap');

@font-face {
  font-family: 'MyCustomFont';
  src: url('../public/fonts/UncutSans-Bold.woff') format('truetype');
  font-weight: normal;
  font-style: normal;
}

/*html, body, #root {*/
/*  height: 100%;*/
/*  margin: 0;*/
/*  padding: 0;*/
/*  width: 100%;*/
/*}*/

/*body {*/
/*  min-height: 100vh;*/
/*  display: flex;*/
/*  flex-direction: column;*/
/*  position: relative;  */
/*}*/

/*#root {*/
/*  display: flex;*/
/*  flex-direction: column;*/
/*  min-height: 100vh;*/
/*}*/